/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import ability from "../../../utils/ability";

let generalSidebar = [{ header: "General" }];

for (let [key, value] of Object.entries({
  "notification-settings": {
    url: "/notification-settings",
    name: "Notificaciones",
    slug: "notification-settings",
    icon: "SettingsIcon",
  },
  store: {
    url: "/stores",
    name: "Agencias y Sucursales",
    slug: "store",
    icon: "BookIcon",
  },
  "media-object": {
    url: "/media-objects",
    name: "Banners y Slides",
    slug: "media-object",
    icon: "ImageIcon",
  },
  menu: {
    url: "/menus",
    name: "Menús",
    slug: "menu",
    icon: "MenuIcon",
  },
  city: {
    url: "/cities",
    name: "Ciudades",
    slug: "state",
    icon: "MapPinIcon",
  },
  "document": {
    url: null,
    name: 'Documentos',
    slug: 'document',
    icon: 'FileTextIcon',
    submenu: [
      {
        url: '/documents/create',
        name: "Registrar documentos",
        slug: "create-document",
      },
      {
        url: '/documents',
        name: 'Listar documentos',
        slug: 'documents'
      },
      {
        url: "/documents/categories",
        name: "Categorías y Subcategorias",
        slug: "categories",
      },
    ]
  },
  job: {
    url: "/jobs",
    name: "Plazas Disponibles",
    slug: "job",
    icon: "UsersIcon",
  },
  product: {
    url: "/products",
    name: "Productos",
    slug: "product",
    icon: "LayersIcon",
  },
  promotion: {
    url: "/promotions",
    name: "Promociones",
    slug: "promotion",
    icon: "ShoppingBagIcon",
  },
  calculator: {
    url: "/calculators",
    name: "Calculadoras",
    slug: "calculator",
    icon: "PercentIcon",
  },
})) {
  if (
    ability.can("create", key) ||
    ability.can("retrieve", key) ||
    ability.can("update", key) ||
    ability.can("delete", key) ||
    ability.can("list", key)
  ) {
    generalSidebar.push(value);
  }
}

let bankSidebar = [{ header: "Banco" }];
for (let [key, value] of Object.entries({
  asset: {
    url: "/assets",
    name: "Activos Eventuales",
    slug: "asset",
    icon: "TruckIcon",
  },
  "static-page": {
    url: "/bank/static-pages",
    name: "Páginas Estáticas",
    slug: "bank-static-page",
    icon: "FileIcon",
  },
  project: {
    url: null,
    name: "Proyectos Inmobiliarios",
    slug: "project",
    icon: "HomeIcon",
    submenu: [
      {
        url: "/real-state-projects/create",
        name: "Registrar proyecto",
        slug: "create-real-state-project",
      },
      {
        url: "/real-state-projects",
        name: "Listar proyectos",
        slug: "real-state-projects",
      },
      {
        url: "/real-state-projects/financing-options",
        name: "Opciones de financiamiento",
        slug: "create-financing-options-real-state-projects",
      },
      {
        url: "/real-state-projects/recycle-bin",
        name: "Papelera de reciclaje",
        slug: "deleted-real-state-projects",
      },
    ],
  },
  payment: {
    url: "/payments",
    name: "Zona de Pagos",
    slug: "payment",
    icon: "CreditCardIcon",
  },
})) {
  if (
    ability.can("create", key) ||
    ability.can("retrieve", key) ||
    ability.can("update", key) ||
    ability.can("delete", key) ||
    ability.can("list", key)
  ) {
    bankSidebar.push(value);
  }
}

let insurancesSidebar = [{ header: "Seguros" }];
for (let [key, value] of Object.entries({
  terminology: {
    url: "/terminologies",
    name: "Glosario",
    slug: "terminology",
    icon: "Edit3Icon",
  },
  "static-page": {
    url: "/insurances/static-pages",
    name: "Páginas Estáticas",
    slug: "insurances-static-page",
    icon: "FileIcon",
  },
  provider: {
    url: "/providers",
    name: "Red de Proveedores",
    slug: "provider",
    icon: "SettingsIcon",
  },
  salvage: {
    url: "/salvages",
    name: "Salvamentos",
    slug: "salvage",
    icon: "BookmarkIcon",
  },
})) {
  if (
    ability.can("create", key) ||
    ability.can("retrieve", key) ||
    ability.can("update", key) ||
    ability.can("delete", key) ||
    ability.can("list", key)
  ) {
    insurancesSidebar.push(value);
  }
}

let companiesSidebar = [{ header: "Empresas" }];
for (let [key, value] of Object.entries({
  "static-page": {
    url: "/companies/static-pages",
    name: "Páginas Estáticas",
    slug: "companies-static-page",
    icon: "FileIcon",
  },
})) {
  if (
    ability.can("create", key) ||
    ability.can("retrieve", key) ||
    ability.can("update", key) ||
    ability.can("delete", key) ||
    ability.can("list", key)
  ) {
    companiesSidebar.push(value);
  }
}

export default [
  ...(generalSidebar.length > 1 ? generalSidebar : []),
  ...(bankSidebar.length > 1 ? bankSidebar : []),
  ...(insurancesSidebar.length > 1 ? insurancesSidebar : []),
  ...(companiesSidebar.length > 1 ? companiesSidebar : []),
];
