<!-- =========================================================================================
    File Name: VxSidebarItem.vue
    Description: Sidebar item component. Extends vuesax framework's 'vs-sidebar-item' component
    Component Name: VxSidebarItem
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
      :class="[
        {'vs-sidebar-item-active': activeLink},
        {'disabled-item pointer-events-none': isDisabled}
      ]"
      class="vs-sidebar--item"
      v-if="canSee"
  >
    <router-link v-if="to" :to="to" :class="[{'router-link-active': activeLink}]" :target="target" exact>
      <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon"/>
      <feather-icon :icon="icon" :class="{'w-3 h-3': iconSmall}" v-else/>
      <slot/>
    </router-link>
    <a v-else :target="target" :href="href">
      <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon"/>
      <feather-icon :icon="icon" :class="{'w-3 h-3': iconSmall}" v-else/>
      <slot/>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'VxSidebarItem',
    props: {
      icon: {
        default: "",
        type: String
      },
      iconSmall: {
        default: false,
        type: Boolean,
      },
      iconPack: {
        default: 'material-icons',
        type: String
      },
      href: {
        default: '#',
        type: String
      },
      to: {
        default: null,
        type: String
      },
      slug: {
        default: null,
        type: String
      },
      index: {
        default: null,
        type: [String, Number]
      },
      featherIcon: {
        default: true,
        type: Boolean,
      },
      target: {
        default: '_self',
        type: String,
      },
      isDisabled: {
        default: false,
        type: Boolean
      },
    },
    data() {
      return {
        activeLink: false,
      }
    },
    watch: {
      '$route'() {
        this.CheckIsActive()
      }
    },
    methods: {
      CheckIsActive() {
        if (this.to) {
          this.activeLink = this.$route.meta.resource === this.slug;
        }
      }
    },
    computed: {
      canSee() {
        return true
      }
    },
    updated() {
      this.CheckIsActive();
    }
  }
</script>
